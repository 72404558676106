<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-center">
              <h1 class="heading-1">Cookie Notice</h1>
              <p class="sub-title">Last updated June 13, 2024</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="pt-4">
              <p class="text">
                This Cookie Notice explains how Resumation ("
                <span class="bold">Company</span>
                ") uses cookies and similar technologies to recognize you when
                you visit our websites at https://www.resumation.net, ("<span
                  class="bold"
                  >Websites</span
                >"). It explains what these technologies are and why the Company
                uses them, as well as your rights to control the Company's use
                of them. In some cases the Company may use cookies to collect
                personal information, or information that becomes personal
                information if the Company combines it with other information.
              </p>
            </div>
            <div class="pt-4">
              <p class="text">
                Change your cookie preferences using the
                <v-btn outlined @click="showCookie">Cookie Manager</v-btn>
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">What are Cookies?</p>
              <p class="text">
                Cookies are small data files that are placed on your computer or
                mobile device when you visit a website. Cookies are widely used
                by website owners in order to make their websites work, or to
                work more efficiently, as well as to provide reporting
                information.
              </p>
              <p class="text">
                Cookies set by the website owner (in this case, Resumation) are
                called "first party cookies". Cookies set by parties other than
                the website owner are called "third party cookies". Third party
                cookies enable third party features or functionality to be
                provided on or through the website (e.g. like advertising,
                interactive content and analytics). The parties that set these
                third party cookies can recognize your computer both when it
                visits the website in question and also when it visits certain
                other websites.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">Why Does the Company Use Cookies?</p>
              <p class="text">
                The Company uses first and third party cookies for several
                reasons. Some cookies are required for technical reasons in
                order for the Company's Websites to operate, and The Company
                refers to these as "essential" or "strictly necessary" cookies.
                Other cookies also enables the Company to track and target the
                interests of the Company's users to enhance the experience on
                the Company's Online Properties. Third parties serve cookies
                through the Company's Websites for advertising, analytics and
                other purposes. This is described in more detail below.
              </p>
              <p class="text">
                The specific types of first and third party cookies served
                through the Company's Websites and the purposes they perform are
                described below (please note that the specific cookies served
                may vary depending on the specific Online Properties you visit).
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">How Can I Control Cookies?</p>
              <p class="text">
                You have the right to decide whether to accept or reject
                cookies. You can exercise your cookie rights by setting your
                preferences in the Cookie Consent Manager. The Cookie Consent
                Manager allows you to select which categories of cookies you
                accept or reject. Essential cookies cannot be rejected as they
                are strictly necessary to provide you with services.
              </p>
              <p class="text">
                The Cookie Manager can be found in the notification banner and
                on the Company's website. If you choose to reject cookies, you
                may still use the Company's website though your access to some
                functionality and areas of the Company's website may be
                restricted. You may also set or amend your web browser controls
                to accept or refuse cookies. As the means by which you can
                refuse cookies through your web browser controls vary from
                browser-to-browser, you should visit your browser's help menu
                for more information.
              </p>
              <p class="text">
                In addition, most advertising networks offer you a way to opt
                out of targeted advertising. If you would like to find out more
                information, please visit
                <a href="https://www.aboutads.info/choices/" target="_blank"
                  >https://www.aboutads.info/choices/</a
                >
                or
                <a href="https://www.youronlinechoices.com" target="_blank"
                  >https://www.youronlinechoices.com</a
                >.
              </p>
              <p class="text">
                The specific types of first and third party cookies served
                through the Company's Websites and the purposes they perform are
                described in the table below (please note that the specific
                cookies served may vary depending on the specific Online
                Properties you visit):
              </p>
              <p class="heading-2 pt-2">Essential website cookies:</p>
              <p class="text">
                These cookies are strictly necessary to provide you with
                services available through our Websites and to use some of its
                features, such as access to secure areas.
              </p>
              <p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Domain</th>
                        <th>Used As</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="ck in ess_ck" :key="ck.name">
                        <td>
                          {{ ck.name }}
                        </td>
                        <td>
                          {{ ck.domain }}
                        </td>
                        <td>
                          {{ ck.party }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </p>
              <p class="heading-2 pt-2">
                Performance and Functionality cookies:
              </p>
              <p class="text">
                These cookies are used to enhance the performance and
                functionality of the Company's Websites but are non-essential to
                their use. However, without these cookies, certain functionality
                may become unavailable.
              </p>
              <p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Domain</th>
                        <th>Used As</th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </p>
              <p class="pl-4">
                There are currently no Performance and Functionality cookies in
                use.
              </p>
              <p class="heading-2 pt-2">Analytics and Customization cookies:</p>
              <p class="text">
                These cookies collect information that is used either in
                aggregate form to help the Company understand how the Company's
                Websites are being used or how effective the Company's marketing
                campaigns are, or to help the Company customize the Company's
                Websites for you.
              </p>
              <p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Domain</th>
                        <th>Used As</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="ck in ac_ck" :key="ck.name">
                        <td>
                          {{ ck.name }}
                        </td>
                        <td>
                          {{ ck.domain }}
                        </td>
                        <td>
                          {{ ck.party }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </p>
              <p class="heading-2 pt-2">Advertising cookies:</p>
              <p class="text">
                These cookies are used to make advertising messages more
                relevant to you. They perform functions like preventing the same
                ad from continuously reappearing, ensuring that ads are properly
                displayed for advertisers, and in some cases selecting
                advertisements that are based on your interests.
              </p>
              <p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Domain</th>
                        <th>Used As</th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </p>
              <p class="pl-4">
                There are currently no Advertising cookies in use.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">
                What About Other Tracking Technologies, Like Web Beacons?
              </p>
              <p class="text">
                Cookies are not the only way to recognize or track visitors to a
                website. The Company may use other, similar technologies from
                time to time, like web beacons (sometimes called "tracking
                pixels" or "clear gifs"). These are tiny graphics files that
                contain a unique identifier that enable the Company to recognize
                when someone has visited the Company's Websites or opened an
                e-mail including them. This allows us, for example, to monitor
                the traffic patterns of users from one page within a website to
                another, to deliver or communicate with cookies, to understand
                whether you have come to the website from an online
                advertisement displayed on a third-party website, to improve
                site performance, and to measure the success of e-mail marketing
                campaigns. In many instances, these technologies are reliant on
                cookies to function properly, and so declining cookies will
                impair their functioning.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">
                Do You Use Flash Cookies Or Local Shared Objects?
              </p>
              <p class="text">
                Websites may also use so-called "Flash Cookies" (also known as
                Local Shared Objects or "LSOs") to, among other things, collect
                and store information about your use of the Company's services,
                fraud prevention and for other site operations. If you do not
                want Flash Cookies stored on your computer, you can adjust the
                settings of your Flash player to block Flash Cookies storage
                using the tools contained in the
                <a
                  href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html#_blank"
                  target="_blank"
                  >Website Storage Settings Panel</a
                >. You can also control Flash Cookies by going to the
                <a
                  href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html#_blank"
                  target="_blank"
                  >Global Storage Settings Panel</a
                >
                and following the instructions (which may include instructions
                that explain, for example, how to delete existing Flash Cookies
                (referred to "information" on the Macromedia site), how to
                prevent Flash LSOs from being placed on your computer without
                your being asked, and (for Flash Player 8 and later) how to
                block Flash Cookies that are not being delivered by the operator
                of the page you are on at the time). Please note that setting
                the Flash Player to restrict or limit acceptance of Flash
                Cookies may reduce or impede the functionality of some Flash
                applications, including, potentially, Flash applications used in
                connection with the Company's services or online content.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">Do You Serve Targeted Advertising?</p>
              <p class="text">
                Third parties may serve cookies on your computer or mobile device to serve advertising through the Company's Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable the Company or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">
                How Often Will You Update This Cookie Notice?
              </p>
              <p class="text">
                The Company may update this Cookie Notice from time to time in order to reflect, for example, changes to the cookies the Company uses or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about the Company's use of cookies and related technologies.
              </p>
              <p class="text">
                The date at the top of this Cookie Notice indicates when it was last updated.
              </p>
            </div>
            <div class="pt-4">
              <p class="heading-1">Where Can I Get Further Information?</p>
              <p class="text">
                If you have any questions about the Company's use of cookies or other technologies, please email the Company at
                <a href="mailto:info@resumation.net">info@resumation.net</a> or
                by post to:
              </p>
              <p class="text bold no-space">Resumation LLC</p>
              <p class="text bold no-space">25 First Ave. SW STE A</p>
              <p class="text bold no-space">Watertown, SD 57201</p>
              <p class="text bold no-space">United States</p>
              <p class="text bold no-space">Phone: (605) 229-9056</p>
              <p class="text bold no-space">
                Email:
                <a href="mailto:info@resumation.net">info@resumation.net</a>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <cookie-manager v-model="showCookieManager"></cookie-manager>
  </v-container>
</template>

<script>
// @ is an alias to /src
import CookieManager from "../components/CookieManager.vue";

export default {
  name: "CookieNotice",
  data: () => ({
    ess_ck: [
      {
        name: "rs_cm",
        domain: "resumation.net",
        party: "1st Party",
      },
      {
        name: "rs_session",
        domain: "resumation.net",
        party: "1st Party",
      },
      {
        name: "rs_ind",
        domain: "resumation.net",
        party: "1st Party",
      },
      {
        name: "ai_session",
        domain: "resumation.net",
        party: "1st Party",
      },
      {
        name: "ai_user",
        domain: "resumation.net",
        party: "1st Party",
      },
      {
        name: "__cf_bm",
        domain: ".static.typecdn.com",
        party: "3rd Party",
      },
    ],
    ac_ck: [
      {
        name: "_ga",
        domain: ".resumation.net",
        party: "1st Party",
      },
      {
        name: "_ga_GTF72248CB",
        domain: ".resumation.net",
        party: "1st Party",
      },
      {
        name: "1P_JAR",
        domain: ".gstatic.com",
        party: "3rd Party",
      },
    ],
    showCookieManager: false,
  }),
  components: {
    "cookie-manager": CookieManager,
  },
  methods: {
    showCookie() {
      this.showCookieManager = true;
    },
  },
};
</script>

<style>
.sub-title {
  color: var(--v-updatedText-base);
}
.heading-1 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.5rem;
}
.heading-2 {
  font-weight: 500;
  font-size: 1.25rem;
}
.upper {
  text-transform: capitalize;
}
.text {
  font-size: 1rem;
}
.bold {
  font-weight: 500;
}
.no-space {
  margin-bottom: 0px !important;
}
</style>
